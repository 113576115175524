import React, { useState } from 'react';

export default function InfoBox ({ini}) {

  console.log(ini);

  const [currentImage, setCurrentImage] = useState(1);

  return (
    <div className="info-box">
      <>
        <img className="image-big" src={`./assets/img/${currentImage}.jpg`} alt="cover thumbnail image"/>
        <div className="images">
          <img onClick={() => setCurrentImage(1)} src="./assets/img/1.jpg" alt="cover thumbnail image"/>
          <img onClick={() => setCurrentImage(2)} src="./assets/img/2.jpg" alt="cover thumbnail image"/>
          <img onClick={() => setCurrentImage(3)} src="./assets/img/3.jpg" alt="cover thumbnail image"/>
        </div>
        <h3>Da Poet + Kayra - NORMAL Albümü 33 Devir Plak</h3>
        <ul>
          <li><span>Bu form ‘Da Poet + Kayra - NORMAL’’ plağı için ön sipariş formudur.</span></li>
          <li>
            <span><b><u>Ambalaj içerisinde 60x30 cm ölçülerinde ve çift taraflı poster yer almaktadır.</u></b></span>
          </li>
          <li><span>Plaklar özel numaralandırılmış olarak SADECE <b>500</b> adet, 180 gr. ve siyah olarak üretilecektir.</span>
          </li>
          <li><span>Plak numaraları ön sipariş tarihine göre belirlenecektir.</span></li>
          <li><span>Üretim ancak ön sipariş tamamlandığında yapılabileceğinden kargoya teslim Temmuz 2024'de mümkün olacaktır.</span>
          </li>
          <li><span>Plaklar imzalı olarak gönderilecektir.</span></li>
          <li><span>Satış fiyatına kargo ücreti dahil <b>değildir</b>.</span></li>
          <li><span>Form aracılığı ile paylaştığınız kişisel bilgiler üçüncü taraf kargo firmaları ile paketlerin size ulaştırılması amacı ile paylaşılacaktır.</span>
          </li>

          <li><span className="rainbow-text"><b>Kalan ürün:</b> {ini.products_left}</span></li>
        </ul>
      </>
      <div className="contact-info">
        <h4>Destek</h4>
        <ul>
          <li><img width={16} src="./assets/svg/email_light.svg"/> <span><a href="mailto:normal@somilk.co"
                                                                            target="_blank">normal@somilk.co</a></span>
          </li>
          <li><img width={16} src="./assets/svg/instagram_light.svg"/> <span><a
            href="https://www.instagram.com/normal.album" target="_blank">normal.album</a></span></li>
        </ul>
      </div>
    </div>);
}