export const provinces = [
  { id: '01', name: 'Adana', districts: ['Adana Merkez', 'Seyhan', 'Ceyhan', 'Yüreğir', 'Sarıçam', 'Karaisalı', 'Pozantı', 'Ceyhan', 'Feke', 'Saimbeyli', 'Tufanbeyli', 'Yumurtalik', 'Kozan'] },
  { id: '02', name: 'Adıyaman', districts: ['Adıyaman Merkez', 'Kahta', 'Besni', 'Gölbaşı'] },
  { id: '03', name: 'Afyonkarahisar', districts: ['Afyonkarahisar Merkez', 'Sultandagi', 'Emirdağ', 'Sinanpaşa'] },
  { id: '04', name: 'Ağrı', districts: ['Ağrı Merkez', 'Diyadin', 'Eleşkirt', 'Patnos', 'Tutak'] },
  { id: '05', name: 'Amasya', districts: ['Amasya Merkez', 'Göynücek', 'Gümüşhacıköy', 'Hamamözü', 'Merzifon', 'Suluova', 'Taşova'] },
  { id: '06', name: 'Ankara', districts: ['Ankara Merkez', 'Çankaya', 'Keçiören', 'Yenimahalle', 'Sincan', 'Etimesgut', 'Gölbaşı', 'Polatlı', 'Akyurt', 'Mamak', 'Altındağ', 'Kızılcahamam', 'Kalecik', 'Elmadağ', 'Şereflikoçhisar', 'Beypazarı', 'Çamlıdere', 'Nallıhan', 'Güdül', 'Haymana', 'Bala', 'Kahramankazan', 'Çubuk', 'Evren'] },
  { id: '07', name: 'Antalya', districts: ['Antalya Merkez', 'Aksu', 'Döşemealtı', 'Kepez', 'Konyaaltı', 'Muratpaşa', 'Serik', 'Alanya', 'Gazipaşa', 'Anamur', 'Akseki', 'Elmalı', 'Finike', 'Gündoğmuş', 'İbradi', 'Kale', 'Kaş', 'Kemer', 'Korkuteli', 'Kumluca', 'Manavgat'] },
  { id: '08', name: 'Artvin', districts: ['Artvin Merkez', 'Ardanuç', 'Arhavi', 'Hopa', 'Borçka', 'Murgul', 'Şavşat', 'Yusufeli'] },
  { id: '09', name: 'Aydın', districts: ['Aydın Merkez', 'Efeler', 'Nazilli', 'Söke', 'Kuşadası', 'Didim', 'Buharkent', 'Çine', 'Germencik', 'Karacasu', 'Karpuzlu', 'Koçarlı', 'Köşk', 'Kuyucak', 'Sultanhisar', 'Yenipazar'] },
  { id: '10', name: 'Balıkesir', districts: ['Balıkesir Merkez', 'Altıeylül', 'Ayvalık', 'Balya', 'Bandırma', 'Bigadiç', 'Burhaniye', 'Dursunbey', 'Edremit', 'Erdek', 'Gönen', 'Havran', 'İvrindi', 'Karesi', 'Kepsut', 'Manyas', 'Marmara', 'Savaştepe', 'Sındırgı', 'Gömeç'] },
  { id: '11', name: 'Bilecik', districts: ['Bilecik Merkez', 'Bozüyük', 'Gölpazarı', 'İnhisar', 'Osmaneli', 'Pazaryeri', 'Söğüt', 'Yenipazar'] },
  { id: '12', name: 'Bingöl', districts: ['Bingöl Merkez', 'Adaklı', 'Genç', 'Karlıova', 'Kiğı', 'Solhan', 'Yayladere', 'Yedisu'] },
  { id: '13', name: 'Bitlis', districts: ['Bitlis Merkez', 'Adilcevaz', 'Ahlat', 'Güroymak', 'Hizan', 'Mutki', 'Tatvan'] },
  { id: '14', name: 'Bolu', districts: ['Bolu Merkez', 'Dörtdivan', 'Gerede', 'Göynük', 'Kıbrıscık', 'Mengen', 'Mudurnu', 'Seben', 'Yeniçağa'] },
  { id: '15', name: 'Burdur', districts: ['Burdur Merkez', 'Ağlasun', 'Altınyayla', 'Bucak', 'Çavdır', 'Çeltikçi', 'Gölhisar', 'Karamanlı', 'Kemer', 'Tefenni', 'Yeşilova'] },
  { id: '16', name: 'Bursa', districts: ['Bursa Merkez', 'Büyükorhan', 'Gemlik', 'Gürsu', 'Harmancık', 'İnegöl', 'İznik', 'Karacabey', 'Keles', 'Kestel', 'Mudanya', 'Mustafakemalpaşa', 'Nilüfer', 'Orhaneli', 'Orhangazi', 'Osmangazi', 'Yenişehir', 'Yıldırım'] },
  { id: '17', name: 'Çanakkale', districts: ['Çanakkale Merkez', 'Ayvacık', 'Bayramiç', 'Biga', 'Bozcaada', 'Çan', 'Eceabat', 'Ezine', 'Gelibolu', 'Gökçeada', 'Lapseki', 'Yenice'] },
  { id: '18', name: 'Çankırı', districts: ['Çankırı Merkez', 'Atkaracalar', 'Çerkeş', 'Eldivan', 'Ilgaz', 'Kızılırmak', 'Korgun', 'Kurşunlu', 'Orta', 'Şabanözü', 'Yapraklı'] },
  { id: '19', name: 'Çorum', districts: ['Çorum Merkez', 'Alaca', 'Bayat', 'Boğazkale', 'Dodurga', 'İskilip', 'Kargı', 'Laçin', 'Mecitözü', 'Oğuzlar', 'Ortaköy', 'Osmancık', 'Sungurlu', 'Uğurludağ'] },
  { id: '20', name: 'Denizli', districts: ['Denizli Merkez', 'Acıpayam', 'Babadağ', 'Baklan', 'Bekilli', 'Beyağaç', 'Bozkurt', 'Buldan', 'Çal', 'Çameli', 'Çardak', 'Çivril', 'Güney', 'Honaz', 'Kale', 'Pamukkale', 'Sarayköy', 'Serinhisar', 'Tavas'] },
  { id: '21', name: 'Diyarbakır', districts: ['Diyarbakır Merkez', 'Bağlar', 'Bismil', 'Çermik', 'Çınar', 'Çüngüş', 'Dicle', 'Eğil', 'Ergani', 'Hani', 'Hazro', 'Kocaköy', 'Kulp', 'Lice', 'Silvan', 'Sur'] },
  { id: '22', name: 'Edirne', districts: ['Edirne Merkez', 'Enez', 'Havsa', 'İpsala', 'Keşan', 'Lalapaşa', 'Meriç', 'Süloğlu', 'Uzunköprü'] },
  { id: '23', name: 'Elazığ', districts: ['Elazığ Merkez', 'Ağın', 'Alacakaya', 'Arıcak', 'Baskil', 'Karakoçan', 'Keban', 'Kovancılar', 'Maden', 'Palu', 'Sivrice'] },
  { id: '24', name: 'Erzincan', districts: ['Erzincan Merkez', 'Çayırlı', 'İliç', 'Kemah', 'Kemaliye', 'Otlukbeli', 'Refahiye', 'Tercan', 'Üzümlü'] },
  { id: '25', name: 'Erzurum', districts: ['Erzurum Merkez', 'Aşkale', 'Aziziye', 'Çat', 'Hınıs', 'Horasan', 'İspir', 'Karaçoban', 'Karayazı', 'Köprüköy', 'Narman', 'Oltu', 'Olur', 'Palandöken', 'Pasinler', 'Pazaryolu', 'Şenkaya', 'Tekman', 'Tortum', 'Uzundere', 'Yakutiye'] },
  { id: '26', name: 'Eskişehir', districts: ['Eskişehir Merkez', 'Alpu', 'Beylikova', 'Çifteler', 'Günyüzü', 'Han', 'İnönü', 'Mahmudiye', 'Mihalgazi', 'Mihalıççık', 'Odunpazarı', 'Sarıcakaya', 'Seyitgazi', 'Sivrihisar', 'Tepebaşı'] },
  { id: '27', name: 'Gaziantep', districts: ['Gaziantep Merkez', 'Araban', 'İslahiye', 'Karkamış', 'Nizip', 'Oğuzeli', 'Yavuzeli', 'Şahinbey', 'Şehitkamil'] },
  { id: '28', name: 'Giresun', districts: ['Giresun Merkez', 'Alucra', 'Bulancak', 'Çamoluk', 'Çanakçı', 'Dereli', 'Doğankent', 'Espiye', 'Eynesil', 'Görele', 'Güce', 'Keşap', 'Piraziz', 'Şebinkarahisar', 'Tirebolu'] },
  { id: '29', name: 'Gümüşhane', districts: ['Gümüşhane Merkez', 'Kelkit', 'Köse', 'Kürtün', 'Şiran', 'Torul'] },
  { id: '30', name: 'Hakkari', districts: ['Hakkari Merkez', 'Çukurca', 'Şemdinli', 'Yüksekova'] },
  { id: '31', name: 'Hatay', districts: ['Antakya', 'Arsuz', 'Belen', 'Dörtyol', 'Erzin', 'Hassa', 'İskenderun', 'Kırıkhan', 'Kumlu', 'Payas', 'Reyhanlı', 'Samandağ', 'Yayladağı'] },
  { id: '32', name: 'Isparta', districts: ['Isparta Merkez', 'Aksu', 'Atabey', 'Eğirdir', 'Gelendost', 'Gönen', 'Keçiborlu', 'Senirkent', 'Sütçüler', 'Şarkikaraağaç', 'Uluborlu', 'Yalvaç', 'Yenişarbademli'] },
  { id: '33', name: 'Mersin', districts: ['Mersin Merkez', 'Akdeniz', 'Anamur', 'Aydıncık', 'Bozyazı', 'Çamlıyayla', 'Erdemli', 'Gülnar', 'Mezitli', 'Mut', 'Silifke', 'Tarsus', 'Toroslar', 'Yenişehir'] },
  { id: '34', name: 'Istanbul', districts: ['Adalar', 'Arnavutköy', 'Ataşehir', 'Avcılar', 'Bağcılar', 'Bahçelievler', 'Bakırköy', 'Başakşehir', 'Bayrampaşa', 'Beşiktaş', 'Beykoz', 'Beylikdüzü', 'Beyoğlu', 'Büyükçekmece', 'Çatalca', 'Çekmeköy', 'Esenler', 'Esenyurt', 'Eyüpsultan', 'Fatih', 'Gaziosmanpaşa', 'Güngören', 'Kadıköy', 'Kağıthane', 'Kartal', 'Küçükçekmece', 'Maltepe', 'Pendik', 'Sancaktepe', 'Sarıyer', 'Silivri', 'Sultanbeyli', 'Sultangazi', 'Şile', 'Şişli', 'Tuzla', 'Ümraniye', 'Üsküdar', 'Zeytinburnu'] },
  { id: '35', name: 'Izmir', districts: ['Aliağa', 'Bayındır', 'Bayraklı', 'Bergama', 'Beydağ', 'Bornova', 'Buca', 'Çeşme', 'Çiğli', 'Dikili', 'Foça', 'Gaziemir', 'Güzelbahçe', 'Karabağlar', 'Karaburun', 'Karşıyaka', 'Kemalpaşa', 'Kınık', 'Kiraz', 'Konak', 'Menderes', 'Menemen', 'Narlıdere', 'Ödemiş', 'Seferihisar', 'Selçuk', 'Tire', 'Torbalı', 'Urla'] },
  { id: '36', name: 'Kars', districts: ['Kars Merkez', 'Akyaka', 'Arpaçay', 'Digor', 'Kağızman', 'Sarıkamış', 'Selim', 'Susuz'] },
  { id: '37', name: 'Kastamonu', districts: ['Kastamonu Merkez', 'Abana', 'Ağlı', 'Araç', 'Azdavay', 'Bozkurt', 'Cide', 'Çatalzeytin', 'Daday', 'Devrekani', 'İhsangazi', 'İnebolu', 'Küre', 'Pınarbaşı', 'Şenpazar', 'Seydiler', 'Taşköprü', 'Tosya'] },
  { id: '38', name: 'Kayseri', districts: ['Kayseri Merkez', 'Akkışla', 'Bünyan', 'Develi', 'Felahiye', 'Hacılar', 'İncesu', 'Kocasinan', 'Melikgazi', 'Özvatan', 'Pınarbaşı', 'Sarıoğlan', 'Sarız', 'Talas', 'Tomarza', 'Yahyalı', 'Yeşilhisar'] },
  { id: '39', name: 'Kırklareli', districts: ['Kırklareli Merkez', 'Babaeski', 'Demirköy', 'Kofçaz', 'Lüleburgaz', 'Pehlivanköy', 'Pınarhisar', 'Vize'] },
  { id: '40', name: 'Kırşehir', districts: ['Kırşehir Merkez', 'Akçakent', 'Akpınar', 'Boztepe', 'Çiçekdağı', 'Kaman', 'Mucur'] },
  { id: '41', name: 'Kocaeli', districts: ['Kocaeli Merkez', 'Başiskele', 'Çayırova', 'Darıca', 'Dilovası', 'Gebze', 'Gölcük', 'İzmit', 'Kandıra', 'Karamürsel', 'Kartepe', 'Körfez'] },
  { id: '42', name: 'Konya', districts: ['Konya Merkez', 'Akşehir', 'Beyşehir', 'Bozkır', 'Cihanbeyli', 'Çeltik', 'Derbent', 'Derebucak', 'Doğanhisar', 'Emirgazi', 'Ereğli', 'Güneysınır', 'Hadim', 'Halkapınar', 'Hüyük', 'Ilgın', 'Kadınhanı', 'Karapınar', 'Kulu', 'Sarayönü', 'Selçuklu', 'Seydişehir', 'Taşkent', 'Tuzlukçu', 'Yalıhüyük', 'Yunak'] },
  { id: '43', name: 'Kütahya', districts: ['Kütahya Merkez', 'Altıntaş', 'Aslanapa', 'Çavdarhisar', 'Domaniç', 'Dumlupınar', 'Emet', 'Gediz', 'Hisarcık', 'Pazarlar', 'Şaphane', 'Simav', 'Tavşanlı'] },
  { id: '44', name: 'Malatya', districts: ['Malatya Merkez', 'Akçadağ', 'Arapgir', 'Arguvan', 'Battalgazi', 'Darende', 'Doğanşehir', 'Doğanyol', 'Hekimhan', 'Kale', 'Kuluncak', 'Pütürge', 'Yazıhan', 'Yeşilyurt'] },
  { id: '45', name: 'Manisa', districts: ['Manisa Merkez', 'Akhisar', 'Alaşehir', 'Demirci', 'Gölmarmara', 'Gördes', 'Kırkağaç', 'Köprübaşı', 'Kula', 'Salihli', 'Sarıgöl', 'Saruhanlı', 'Selendi', 'Soma', 'Şehzadeler', 'Turgutlu', 'Yunusemre'] },
  { id: '46', name: 'Kahramanmaraş', districts: ['Kahramanmaraş Merkez', 'Afşin', 'Andırın', 'Çağlayancerit', 'Dulkadiroğlu', 'Ekinözü', 'Elbistan', 'Göksun', 'Nurhak', 'Onikişubat', 'Pazarcık', 'Türkoğlu'] },
  { id: '47', name: 'Mardin', districts: ['Mardin Merkez', 'Dargeçit', 'Derik', 'Kızıltepe', 'Mazıdağı', 'Midyat', 'Nusaybin', 'Ömerli', 'Savur', 'Yeşilli'] },
  { id: '48', name: 'Muğla', districts: ['Muğla Merkez', 'Bodrum', 'Dalaman', 'Datça', 'Fethiye', 'Kavaklıdere', 'Köyceğiz', 'Marmaris', 'Menteşe', 'Milas', 'Ortaca', 'Seydikemer', 'Ula', 'Yatağan'] },
  { id: '49', name: 'Muş', districts: ['Muş Merkez', 'Bulanık', 'Hasköy', 'Korkut', 'Malazgirt', 'Varto'] },
  { id: '50', name: 'Nevşehir', districts: ['Nevşehir Merkez', 'Avanos', 'Derinkuyu', 'Gülşehir', 'Hacıbektaş', 'Kozaklı', 'Ürgüp'] },
  { id: '51', name: 'Niğde', districts: ['Niğde Merkez', 'Altunhisar', 'Bor', 'Çamardı', 'Çiftlik', 'Ulukışla'] },
  { id: '52', name: 'Ordu', districts: ['Ordu Merkez', 'Akkuş', 'Altınordu', 'Aybastı', 'Çamaş', 'Çatalpınar', 'Çaybaşı', 'Fatsa', 'Gölköy', 'Gülyalı', 'Gürgentepe', 'İkizce', 'Kabadüz', 'Kabataş', 'Korgan', 'Kumru', 'Mesudiye', 'Perşembe', 'Ulubey', 'Ünye'] },
  { id: '53', name: 'Rize', districts: ['Rize Merkez', 'Ardeşen', 'Çamlıhemşin', 'Çayeli', 'Derepazarı', 'Fındıklı', 'Güneysu', 'Hemşin', 'İkizdere', 'İyidere', 'Kalkandere', 'Pazar'] },
  { id: '54', name: 'Sakarya', districts: ['Sakarya Merkez', 'Akyazı', 'Arifiye', 'Erenler', 'Ferizli', 'Geyve', 'Hendek', 'Karapürçek', 'Karasu', 'Kaynarca', 'Kocaali', 'Pamukova', 'Sapanca', 'Serdivan', 'Söğütlü', 'Taraklı'] },
  { id: '55', name: 'Samsun', districts: ['Samsun Merkez', 'Alaçam', 'Asarcık', 'Atakum', 'Ayvacık', 'Bafra', 'Canik', 'Çarşamba', 'Havza', 'İlkadım', 'Kavak', 'Ladik', 'Salıpazarı', 'Tekkeköy', 'Terme', 'Vezirköprü', 'Yakakent'] },
  { id: '56', name: 'Siirt', districts: ['Siirt Merkez', 'Baykan', 'Eruh', 'Kurtalan', 'Pervari', 'Şirvan'] },
  { id: '57', name: 'Sinop', districts: ['Sinop Merkez', 'Ayancık', 'Boyabat', 'Dikmen', 'Durağan', 'Erfelek', 'Gerze', 'Saraydüzü', 'Türkeli'] },
  { id: '58', name: 'Sivas', districts: ['Sivas Merkez', 'Akıncılar', 'Altınyayla', 'Divriği', 'Doğanşar', 'Gemerek', 'Gölova', 'Hafik', 'İmranlı', 'Kangal', 'Koyulhisar', 'Sarkışla', 'Şarkışla', 'Suşehri', 'Ulaş', 'Yıldızeli', 'Zara'] },
  { id: '59', name: 'Tekirdağ', districts: ['Tekirdağ Merkez', 'Çerkezköy', 'Çorlu', 'Ergene', 'Hayrabolu', 'Kapaklı', 'Malkara', 'Marmaraereğlisi', 'Muratlı', 'Saray', 'Süleymanpaşa', 'Şarköy'] },
  { id: '60', name: 'Tokat', districts: ['Tokat Merkez', 'Almus', 'Artova', 'Başçiftlik', 'Erbaa', 'Niksar', 'Pazar', 'Reşadiye', 'Sulusaray', 'Turhal', 'Yeşilyurt', 'Zile'] },
  { id: '61', name: 'Trabzon', districts: ['Trabzon Merkez', 'Akçaabat', 'Araklı', 'Arsin', 'Beşikdüzü', 'Çarşıbaşı', 'Çaykara', 'Dernekpazarı', 'Düzköy', 'Hayrat', 'Köprübaşı', 'Maçka', 'Of', 'Sürmene', 'Şalpazarı', 'Tonya', 'Vakfıkebir', 'Yomra'] },
  { id: '62', name: 'Tunceli', districts: ['Tunceli Merkez', 'Çemişgezek', 'Hozat', 'Mazgirt', 'Nazımiye', 'Ovacık', 'Pertek', 'Pülümür'] },
  { id: '63', name: 'Şanlıurfa', districts: ['Şanlıurfa Merkez', 'Akçakale', 'Birecik', 'Bozova', 'Ceylanpınar', 'Eyyübiye', 'Halfeti', 'Harran', 'Hilvan', 'Siverek', 'Suruç', 'Viranşehir'] },
  { id: '64', name: 'Uşak', districts: ['Uşak Merkez', 'Banaz', 'Eşme', 'Karahallı', 'Sivaslı', 'Ulubey'] },
  { id: '65', name: 'Van', districts: ['Van Merkez', 'Başkale', 'Çaldıran', 'Çatak', 'Edremit', 'Erciş', 'Gevaş', 'Gürpınar', 'Muradiye', 'Özalp', 'Saray', 'Tuşba'] },
  { id: '66', name: 'Yozgat', districts: ['Yozgat Merkez', 'Akdağmadeni', 'Aydıncık', 'Boğazlıyan', 'Çandır', 'Çayıralan', 'Çekerek', 'Kadışehri', 'Saraykent', 'Sarıkaya', 'Sorgun', 'Şefaatli', 'Yenifakılı', 'Yerköy'] },
  { id: '67', name: 'Zonguldak', districts: ['Zonguldak Merkez', 'Alaplı', 'Çaycuma', 'Devrek', 'Ereğli', 'Gökçebey'] },
  { id: '68', name: 'Aksaray', districts: ['Aksaray Merkez', 'Ağaçören', 'Eskil', 'Gülağaç', 'Güzelyurt', 'Ortaköy', 'Sarıyahşi'] },
  { id: '69', name: 'Bayburt', districts: ['Bayburt Merkez', 'Aydıntepe', 'Demirözü'] },
  { id: '70', name: 'Karaman', districts: ['Karaman Merkez', 'Ayrancı', 'Başyayla', 'Ermenek', 'Kazımkarabekir', 'Sarıveliler'] },
  { id: '71', name: 'Kırıkkale', districts: ['Kırıkkale Merkez', 'Bahşili', 'Balışeyh', 'Çelebi', 'Delice', 'Karakeçili', 'Keskin', 'Sulakyurt', 'Yahşihan'] },
  { id: '72', name: 'Batman', districts: ['Batman Merkez', 'Beşiri', 'Gercüş', 'Hasankeyf', 'Kozluk', 'Sason'] },
  { id: '73', name: 'Şırnak', districts: ['Şırnak Merkez', 'Beytüşşebap', 'Cizre', 'Güçlükonak', 'İdil', 'Silopi', 'Uludere'] },
  { id: '74', name: 'Bartın', districts: ['Bartın Merkez', 'Amasra', 'Kurucaşile', 'Ulus'] },
  { id: '75', name: 'Ardahan', districts: ['Ardahan Merkez', 'Çıldır', 'Damal', 'Göle', 'Hanak', 'Posof'] },
  { id: '76', name: 'Iğdır', districts: ['Iğdır Merkez', 'Aralık', 'Karakoyunlu', 'Tuzluca'] },
  { id: '77', name: 'Yalova', districts: ['Yalova Merkez', 'Altınova', 'Armutlu', 'Çınarcık', 'Çiftlikköy', 'Termal'] },
  { id: '78', name: 'Karabük', districts: ['Karabük Merkez', 'Eflani', 'Eskipazar', 'Ovacık', 'Safranbolu', 'Yenice'] },
  { id: '79', name: 'Kilis', districts: ['Kilis Merkez', 'Elbeyli', 'Musabeyli', 'Polateli'] },
  { id: '80', name: 'Osmaniye', districts: ['Osmaniye Merkez', 'Bahçe', 'Düziçi', 'Hasanbeyli', 'Kadirli', 'Sumbas', 'Toprakkale'] },
  { id: '81', name: 'Düzce', districts: ['Düzce Merkez', 'Akçakoca', 'Cumayeri', 'Çilimli', 'Gölyaka', 'Gümüşova', 'Kaynaşlı', 'Yığılca'] },
];



// export const provincesArray = Object.entries(provinces).map(([key, value]) => {
//   return {
//     id: key,
//     ...value,
//   }
// });

// export const provincesArray = Object.keys(provinces).map((plaka) => {
//   return {
//     id: plaka,
//     ...provinces[plaka],
//   };
// });