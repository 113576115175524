import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
// AddressElement,
//  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import InputMask from 'react-input-mask';

import { provinces } from './constants/provinces';
import { isLocalHost } from './constants';

export const baseURL = isLocalHost ? 'http://localhost:3000/' : 'https://normalalbum.com/'; // FE URL not api

export default function CheckoutForm ({ updatePI, logError }) {
  const stripe = useStripe();
  const elements = useElements();

  const [selectedDistricts, setSelectedDistricts] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    line1: '',
    count: '1',
    signed: 'no',
    signedTo: '',
  });

  // Create a function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // console.log(formData);

  // console.log(elements?._commonOptions?.clientSecret);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // await is needed since update has to be done before the payment confirm.
    await updatePI(formData).then(() => {
      // how about confirmPayment in here? but it is async and has await already
      // setIsLoading(false);
    });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: baseURL,
        receipt_email: formData.email,
        payment_method_data: {
          billing_details: {
            name: formData?.name,
            email: formData?.email,
            phone: formData?.phone,
            address: {
              state: formData?.province,
              city: formData?.district,
              line1: formData?.line1,
              country: 'TR',
            },
          },
        },
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else if(error.type === 'invalid_request_error' && error.code === 'email_invalid') {
      setMessage(error.message);
    } else {
      setMessage('Beklenmedik bir hata oluştu.');
      logError({error, formData });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          country: 'never',
        },
      },
    },
  };

  // const cardStyle = {
  //   base: {
  //     borderRadius: '6px',
  //     marginBottom: '16px',
  //     padding: '12px',
  //     border: '1px solid rgba(50, 50, 93, 0.1)',
  //     maxHeight: '44px',
  //     fontSize: '16px',
  //     width: '100%',
  //     background: 'white',
  //     boxSizing: 'border-box',
  //   },
  // };

  const numbers = Array.from({ length: 5 }, (_, index) => index + 1);
  // const numbers2 = Array(5).fill().map((_, index) => index + 1);

  useEffect(() => {
    setIsLoading(true);
    updatePI({ count: formData.count }).then(() => setIsLoading(false));
  }, [formData.count]);


  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <>
        <div className="input-group">
          <label htmlFor="name">Alıcı Adı Soyadı</label>
          <input
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formData?.name}
            //#required
          />

        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            onChange={handleInputChange}
            value={formData?.email}
            //#required
          />

        </div>
        <div className="input-group">
          <label htmlFor="phone">Telefon</label>
          <InputMask
            mask="+\90 599 999 99 99"
            type="text"
            name="phone"
            onChange={handleInputChange}
            value={formData?.phone}
            //#required
          />

        </div>
        <div className="input-group">

          <label htmlFor="count">Adet</label>
          <select
            name="count"
            onChange={handleInputChange}
            //#required
          >
            {numbers.map(number => <option value={number}>{number}</option>)}
          </select>

        </div>
        <div className="input-group">
          <label htmlFor="price">Fiyat</label>
          <input
            type="text"
            name="price"
            value="21€ / 730₺"
            disabled
          />
          <span className="input-feedback">*Güncel fiyat döviz kuruna bağlı olarak değişkenlik gösterebilir.</span>

        </div>
        <div className="input-group">
          {/*<label htmlFor='product'>Ürün:</label>
          <input
            type="text"
            name="product"
            disabled value=''
          />*/}

        </div>
        <div className="input-group">
          {/*<label for="postalCode">Postal Code</label>*/}
          {/*<input onChange={handleInputChange} type="text" id="postalCode" name="postalCode" />*/}

        </div>
        <div className="input-group">
          <label htmlFor="province">İl</label>
          <select
            name="province"
            onChange={(e) => {
              setSelectedDistricts(provinces.find(({ name }) => name === e.target.value).districts);
              // handleInputChange(e);
              setFormData({
                ...formData,
                province: e.target.value,
                district: null,
              });
            }}
            //#required
          >
            <option onClick={() => setSelectedDistricts([])} hidden>Seçiniz</option>
            {provinces.map(({ id, name }) => <option value={name} key={id}>{name}</option>)}
          </select>

        </div>
        <div className="input-group">
          <label htmlFor="district">İlçe</label>
          <select
            name="district"
            onChange={handleInputChange}
            disabled={!selectedDistricts.length}
            //#required
          >
            <option value="" selected={!formData?.district} hidden>Seçiniz</option>
            {selectedDistricts.map(name => <option value={name}>{name}</option>)}
          </select>

        </div>
        <div className="input-group">
          <label htmlFor="line1">Açık Adres</label>
          <textarea
            onChange={handleInputChange}
            name="line1"
            value={formData?.line1}
            //#required
          />

        </div>
        <div className="input-group">
          <label htmlFor="signed">İmzalansın</label>
          <select
            id="signed"
            name="signed"
            //#required
            onChange={handleInputChange}
            className={formData.signed === 'yes' ? 'is-open' : ''}
          >
            <option value="no" selected>Hayır</option>
            <option value="yes">Evet</option>
          </select>

          {formData.signed === 'yes' && false ? (
            <>
              <input
                onChange={handleInputChange}
                type="text"
                name="signedTo"
                placeholder="Kimin adına imzalansın"
                value={formData?.signedTo}
                //#required
              />
              {formData?.count > 1 ? (
                <span className="input-feedback">*Eğer birden fazla ön sipariş verdiyseniz imzalanacak isimleri arasına virgül koyarak yazınız. (Örneğin: Burak, Melike, İrem, Hakan)</span>
              ) : null}
            </>
          ) : null}
        </div>
        {/*<div className="input-group">*/}
        {/*<label for="country">Country</label>*/}
        {/*<select id="country" name="country" >*/}
        {/*  <option value="US">United States</option>*/}
        {/*  <option value="CA">Canada</option>*/}
        {/*</select>*/}

        {/*<AddressElement options={{*/}
        {/*  mode: 'shipping',*/}
        {/*  allowedCountries: ['TR'],*/}
        {/*  fields: {*/}
        {/*    phone: 'always',*/}
        {/*  },*/}
        {/*  validation: {*/}
        {/*    phone: {*/}
        {/*      : 'never',*/}
        {/*    },*/}
        {/*  },*/}
        {/*}}/>*/}

        {/*<span className='separator'>Ödeme</span>*/}
        {/*</div>*/}

        <div className="input-group">
          <label htmlFor="price">Toplam Fiyat</label>
          <input
            type="text"
            name="price"
            value={`${formData.count * 21}€`}
            disabled
          />
        </div>
        {/*<CardElement className="card-element" />*/}
        <PaymentElement id="payment-element" options={paymentElementOptions}/>
        {/*<AddressElement options={{mode: 'billing'}} />*/}
        <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : 'Ön Sipariş Ver'}
            </span>
        </button>
        {message ? (<div className="alert alert-danger">{message}</div>) : null}
        <div className="stripe">
          <a href="https://www.stripe.com" target='_blank'>
            <img src="./assets/svg/stripe_black.svg"/>
          </a>
          <span>Kredi kartı işlemleri dünyanın en güvenilir kredi kartı sağlayıcından birisi olan 'Stripe' tarafından yapılmaktadır. Girmiş olduğunuz bütün bilgiler 256bit şifreleme ile iletilmektedir.</span>
        </div>
      </>
    </form>
  );
}